<template>
    <v-dialog v-model="dialog" width="800">
    <span style="color:red;" v-if="disablePlayTask">disablePlayTask</span>
    <v-container class="kc-csv-processor px-lg-7 pt-lg-0" style="height: 100%; width: 100%;background: white;">
        <!-- <v-row dense class="py-1">CSVProcesssorView</v-row> -->
        <!-- <v-btn @click="createClick()">Create</v-btn> -->
                <v-row class="mt-0">
                    <template>
                    <v-col   cols=10>
                        <v-text-field :disabled="readOnlyMode == true" hide-details v-model="configName" class="py-1 shrink" label="Name"></v-text-field>
                    </v-col>
                    <v-col cols=2 class="text-right">
                        <v-tooltip v-if="showPlusButton" top>
                            <template v-slot:activator="{ on}">
                                <v-btn v-on="on" small class="btn-icon mt-5 mr-2" @click="addProcessClick()">                        
                                    <v-icon>
                                        add
                                    </v-icon>
                                </v-btn>
                            </template>
                            Add CSV Process
                        </v-tooltip>                        
                        <!-- <v-tooltip top>
                            <template v-slot:activator="{ on}">
                                <v-btn v-on="on" small class="btn-icon mt-5 mr-2" @click="exportClick()">                        
                                    <v-icon>
                                        download
                                    </v-icon>
                                </v-btn>
                            </template>
                            Export CSV to file
                        </v-tooltip>                         -->
                    </v-col>
                    </template>
                    <!-- <templae v-else>
                        <v-col cols=12></v-col>
                    </templae> -->
                </v-row>
                <v-row >
                    <v-col class="pt-1" cols="1">
                        Source:
                    </v-col>
                    <v-col v-if="csvUrl" class="pt-1" cols="11">
                        <label style="font-size:0.9em;">{{cookCSVUrl}}</label>
                    </v-col>
                    <v-col v-else style="margin-top:-10px;" class="pt-1" cols="11">
                        <v-tooltip top>
                            <template v-slot:activator="{ on}">
                        <v-file-input   @mouseenter.native='on.mouseenter'
            @mouseleave.native='on.mouseleave' ref="localFileInput"  accept=".zip, .csv, .txt" hide-details class="pt-1 shrink" v-model="localFileInput" label="CSV or TXT File" prepend-icon="upload_file"
                            @change="checkJSON"></v-file-input>
                            </template>
                        Upload file                            
                        </v-tooltip>
                    </v-col>  
                </v-row>
        <!-- <KCCreateComponent v-model="show" :url="csvUrl"></KCCreateComponent> -->
        <KCAddCSVProcessDialog v-model="addDlg" :headers="effectiveHeaders" :config="selectedConfig" :configItems="configItems"
            :sampleData="effectiveData" :editMode="addDlgUpdate" :readOnlyMode="addDlgReadOnly" :taskName="configName" @commit="addCommit" @close="dlgClose"></KCAddCSVProcessDialog>
        <div v-if="records.length > 0"><!-- can not use records.length >= 0 here strange bug -->
        <v-row class="pt-2">
            <v-col cols=12>
                <v-data-table dense style="width=100%;" :hide-default-footer="true" :headers="configHeader" :items="configItems"
                    :item-class="itemRowBackground" :items-per-page="20" class="elevation-1">
                    <template v-slot:[`item.action`]="props">
                        <v-tooltip v-if="readOnlyMode==false  && replayMode==false" top>
                            <template v-slot:activator="{ on , attrs}">
                        <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" v-if="props.index != 0"  class="mr-2" @click="doAction('up', props)">
                            arrow_drop_up
                        </v-icon>
                            </template>
                            <span>Move up</span>
                        </v-tooltip>
                        <v-tooltip v-if="readOnlyMode==false  && replayMode==false" top>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" v-if="props.index != configItems.length - 1"  class="mr-2"
                                    @click="doAction('down', props)">
                                    arrow_drop_down
                                </v-icon>
                            </template>
                            <span>Move down</span>                                
                        </v-tooltip>
                        <v-tooltip  v-if="props.item.error && replayMode==false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="red" small class="mr-2"
                                    @click="doAction('edit', props)">
                                    mode
                                </v-icon>
                            </template>
                            <span>{{ props.item.error }}</span>
                        </v-tooltip>
                        <v-tooltip  v-else top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon  v-bind="attrs" v-on="on"  v-if="!props.item.error && showPencilButton && replayMode==false" small class="mr-2" @click="doAction('edit', props)">
                                    {{editIcon}}
                                </v-icon>
                                <v-icon  v-bind="attrs" v-on="on"  v-if="replayMode==true" small class="mr-2" @click="doAction('view', props)">
                                    remove_red_eye
                                </v-icon>
                            </template>
                            <span>{{editToolTip}}</span>
                        </v-tooltip>
                        <v-tooltip v-if="readOnlyMode==false  && replayMode==false" top>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon v-bind="attrs" v-on="on" :disabled="readOnlyMode == true" small class="mr-2" @click="doAction('delete', props)">
                                    delete
                                </v-icon> 
                            </template>
                            <span>Remove</span>
                        </v-tooltip>

                    </template>
                    <template v-slot:[`item.enable`]="props">
                        <v-simple-checkbox off-icon="check_box_outline_blank" on-icon="check_box" :disabled="readOnlyMode == true" v-model="props.item.enable" :ripple="false"></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.detail`]="props">
                        <div class="truncate">{{ props.item.detail}}</div>
                    </template>                    
                </v-data-table>
            </v-col>
        </v-row>
        <!-- {{testDataTable.items}} -->
        <v-expansion-panels v-if="records.length > 0" class="pb-3 pt-2" :multiple=true v-model="panels">
            <v-expansion-panel class="preview-datatable">
                <v-expansion-panel-header class="px-4 py-1" style="min-height:30px;">
                    Preview
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-1">
                    <v-row class="pt-0 mt-0" v-if="configItems.length >= 0 && testDataTable.items.length > 0">
                        <v-col class="pt-0" cols=12>
                            <v-data-table dense :hide-default-footer="true" :headers="testDataTable.headers" :items="testDataTable.items" :items-per-page="5"
                                class="elevation-1">
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- <v-expansion-panel class="mt-1">
                <v-expansion-panel-header class="px-4 py-1" style="min-height:30px;">
                    When finish
                </v-expansion-panel-header>
            </v-expansion-panel> -->
        </v-expansion-panels>
        </div>
        <v-row class="justify-end">
            <!-- {{this.experimentMode}} -->
            <!-- <v-btn color="blue darken-1" text @click="uploadFileToS3WithSwal(localFileInput)">
                UploadToS3
            </v-btn> -->
            <v-btn v-if="isLocalSite" color="blue darken-1" text @click="testStream()">
                Test
            </v-btn>

            <v-btn v-if="isLocalSite" color="blue darken-1" text @click="localProcessCSV()">
                localProcessCSV
            </v-btn>

            <v-btn color="blue darken-1" text @click="closeClick()">
                Close
            </v-btn>
            <template v-if="replayMode">
                <v-btn v-if="canReplay == true" color="blue darken-1" text  @click="replayCSVConfig()">
                    Process
                </v-btn>
            </template>
            <template v-else>
                <v-btn v-if="canSubmit == true" color="blue darken-1" text  @click="saveCSVConfig(false)">
                    Submit
                </v-btn>
            </template>
            <v-btn v-if="canSubmit == true && this.experimentMode==true" color="blue darken-1" text  @click="saveCSVConfig(true)">
                Submit 2
            </v-btn>

        </v-row>
        <!-- {{localFileInput}} -->
    </v-container>
    <!-- "@zip.js/zip.js": "^2.6.25", -->
    <script type="application/javascript" onload="scriptLoaded()" defer src="zip.min.js"></script>
    </v-dialog>
</template>
<style>
.kc-csv-processor .script-error {
    background-color: #FFEBEE;
}

.kc-csv-processor .script-error:hover {
    background-color: #FFCDD2 !important;
}

.kc-csv-processor div.truncate {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kc-csv-processor .btn-icon {
    width: 36px;min-width: unset;
}

/* .kc-csv-processor  .v-data-footer__select > div.v-select {
  margin-top: 0px;
}

.kc-csv-processor  .v-data-footer__select .v-input__control {
  height:20px;
} */

.kc-csv-processor .preview-datatable .v-expansion-panel-content__wrap {
    padding:0 0;    
}

.swal2-styled.swal2-cancel.kc-button {
    margin-top: 20px;
    width: 50%;
}

.swal2-container .meter { 
  height: 20px;  
  position: relative;
  background: #eae4e3;
  border-radius: 25px;
  /* padding: 10px; */
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.swal2-container .meter > span {
  display: block;
  height: 20px;  
  /* border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; */
  background-color: #a0a8ae;
  /* background-image: linear-gradient(
    center bottom,
    rgb(43,194,83) 37%,
    rgb(84,240,84) 69%
  ); */
  box-shadow: 
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
  position: relative;
  overflow: hidden;
  border-radius: 25px;
}
/* .swal2-container .meter.orange > span {
  background-color: #f1a165;
  background-image: linear-gradient(to bottom, #f1a165, #f36d0a);
}

.swal2-container .meter.red > span {
  background-color: #f0a3a3;
  background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
} */
</style>
<script>
// Need this to allow zip to include from <script> and prevewnt lint compiling error 
/* eslint-disable */

import axios from "axios";

// import csvParser from "csv-parser"
import utils from "../services/KCUtils.js";
import csvUtils from "../services/KCCSVUtils.js";
// import KCCreateComponent from "@/components/KCCreateComponent.vue";
import KCAddCSVProcessDialog from "@/components/KCAddCSVProcessDialog.vue";
// var parse = require('csv-parse');
//import { parse } from "csv-parse/sync";
//import {parse as  parse_stream} from "csv-parse";
import Papa from 'papaparse';
//import { parse } from 'csv-parse';
const { convertArrayToCSV } = require('convert-array-to-csv');
//const zip = require('@zip.js/zip.js');
//import * as zip from "@zip.js/zip.js";
//const converter = require('convert-array-to-csv');
const maxSampleCSV = 1024*20; 
const allowedExtensions = /(\.zip|\.csv|\.txt)$/i;

window.scriptLoaded = ()=>{
    console.log("scriptLoaded");
}

function charFrequency(string) {
    var freq = {};
    for (var i = 0; i < string.length; i++) {
        var character = string.charAt(i);
        if (freq[character]) {
            freq[character]++;
        } else {
            freq[character] = 1;
        }
    }

    return freq;
}

function csvDetectDelimiter(data, dellist = ["|", ",", ";"]) {
    var freq = charFrequency(data);
    var counts = [];
    for (var i = 0; i < dellist.length; i++) {
        var ch = dellist[i];
        if (ch in freq) {
            counts.push(freq[ch]);
        } else {
            counts.push(0);
        }
    }

    var max = Math.max(...counts);
    console.log(counts, max);
    return dellist[counts.indexOf(max)];
}


function toUpperCase(x) {
    return ("" + x).toUpperCase();
}
function toLowerCase(x) {
    return ("" + x).toLowerCase();
}
function formatNumber(x, local = "en-US") {
    var number = Number(x);
    if (!isNaN(number)) {
        var internationalNumberFormat = new Intl.NumberFormat('en-US');
        return internationalNumberFormat.format(number);
    } else {
        return x;
    }
}



function calculatedValue(row, calculatedData, ignoreEmpty=true, currentValue = undefined) {
    var script = ""
    if (currentValue != undefined) {
        var x = currentValue;
        var $ = currentValue;
    }
    for (var i = 0; i < row.length; i++) {
        var value = utils.addslashes(row[i]);
        script += `var $${(i + 1)} = "${value}";\n`;
    }
    script += calculatedData;
    //console.log(script);
    try{
        var ret = eval(script);
        return ret;
    }catch(ex){
        console.error(ex);
        console.error(script);
        throw ex;
    }
}

function transformColumn(items, config, onException = undefined) {
    if (items.length >= 1) {
        var ret = [];
        var x, cindex;
        var header = [...items[0]];
        ret.push(header);
        cindex = csvUtils.getColumnIndex(header,config.column);
        if(config.chkEvalMode == false){
            var value = config.value.replaceAll("{}",`{${header[cindex]}}`);
            var subCommand = csvUtils.createSubsitiueCommand(value,header);
            //var subCommand = csvUtils.createSubsitiueCommand(config.value,header);
        }
        for (var i = 1; i < items.length; i++) {
            var row = [... items[i]];
            if( config.ignoreEmpty==true && row[cindex]==""){
                ret.push(row);
                continue;
            }else{
                var calcv = "";
                if(config.chkEvalMode==true){
                    try {
                        calcv = calculatedValue(row,config.value, config.ignoreEmpty, row[cindex]);
                        //row.push(calcv);
                    } catch (ex) {
                        if (onException != undefined) {
                            onException(i, ex);
                        }
                        //row.push(items[i][x]);
                    }
                }else{
                    calcv = csvUtils.subsituteValue(row,subCommand);
                }
                row[cindex] = calcv;
            }
            // var row = [];
            // for (x = 0; x < items[i].length; x++) {
            //     if(x == config.column){
            //         var calcv = "";
            //         if( config.ignoreEmpty==true && items[i][x]==""){
            //             row.push(items[i][x]);
            //         }else{
            //             try {
            //                 calcv = calculatedValue(items[i],config.value, config.ignoreEmpty, items[i][x]);
            //                 row.push(calcv);
            //             } catch (ex) {
            //                 if (onException != undefined) {
            //                     onException(i, ex);
            //                 }
            //                 row.push(items[i][x]);
            //             }
            //         }
            //     }else{
            //         row.push(items[i][x]);
            //     }
            // }
            ret.push(row)
        }
        return ret;
    } else {
        return items;
    }

}

function getUsedColumn(str){
    var usedCols = new Set();
    const regex = /\$(\d+)|\${\$(\d+)}/gm;
    var m;
    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        usedCols.add(parseInt(m[1]));
    }        
    //console.log("usedCols",usedCols);
    return usedCols;
}

function subsituteColumn(items, config){
    if (items.length >= 1) {
        var subCommand = csvUtils.createSubsitiueCommand(config.value,items[0]);
        var ret = [];
        var header = [...items[0]];
        header.push(config.name)
        ret.push(header);
        for (var i = 1; i < items.length; i++) {
            var row = [...items[i]];
            var calcv = csvUtils.subsituteValue(row,subCommand);
            row.push(calcv);
            ret.push(row);
        }
        return ret;
    }else{
        return items;
    }
}

class CSVValidationError extends Error {
  constructor(message) {
    super(message); // (1)
    this.name = "CSVValidationError"; // (2)
  }
}

function isColumnExist(header,columnName){
    return csvUtils.getColumnIndex(header,columnName) >= 0
}


function calculatedColumn(items, config, onException = undefined) {
    var x;
    if(isColumnExist(items[0],config.name)){
        if(onException){
            onException(0,new CSVValidationError(`Column [${config.name}] already exist`));
        }
    }
    if(config.chkEvalMode == false){
        return subsituteColumn(items,config);
    }
    if (items.length >= 1) {
        var usedCols = new Set();
        if(config.ignoreEmpty==true){
            usedCols = getUsedColumn(config.value);
        }
        var ret = [];
        var header = [...items[0]];
        header.push(config.name)
        ret.push(header);
        var scriptValue = csvUtils.convertColNameToColNum(config.value,header);
        for (var i = 1; i < items.length; i++) {
            var row = [...items[i]];
            var calcv = "";
            var shouldSkip = false;
            for(x=0;x<row.length;x++){
                if(usedCols.has(x+1)){
                    if(row[x] == ""){
                        shouldSkip = true;
                        break;
                    }
                }
            }
            if(shouldSkip==false){
                try {
                    calcv = calculatedValue(row, scriptValue,config.ignoreEmpty);
                } catch (ex) {
                    if (onException != undefined) {
                        onException(i, ex);
                    }
                }
            }
            row.push(calcv);
            ret.push(row);
        }

        return ret;
    } else {
        return items;
    }
}

function reorderColumn(items, config, onException = undefined) {
    if (items.length >= 1) {
        var ret = [];
        var ordHeader = [...items[0]];
        var header = [];
        var x;
        for(x in config){
            if(config[x].select){
                var index = csvUtils.getColumnIndex(ordHeader,config[x].name) //ordHeader.indexOf(config[x].name);
                if(index <0){
                    if(onException){
                        onException(0,new CSVValidationError(`Column [${config[x].name}] not exist`));
                    }
                }
                header.push(config[x].rename);
            }
        }
        ret.push(header);
        for (var i = 1; i < items.length; i++) {
            var row = [];
            for(x in config){
                if(config[x].select){
                    // find original index
                    index = csvUtils.getColumnIndex(ordHeader,config[x].name);//ordHeader.indexOf(config[x].name);
                    row.push(items[i][index]);
                }
            }
            ret.push(row);
        }        
        return ret;
    }else {
        return items;
    }
}

function sortByColumn(items, config, onException = undefined) {
    if (items.length >= 1) {
        var ret = [];
        var header = [...items[0]];
        var x;
        ret.push(header);
        var tmp = items.slice(1);
        // check header 
        for(x =0; x< config.length;x++){
            if(config[x].select == true){
                 var index = csvUtils.getColumnIndex(header,config[x].name);//header.indexOf(config[x].name);
                 if(index < 0){
                    if(onException){
                        onException(0,new CSVValidationError(`Column [${config[x].name}] not exist`));
                    }
                 }
            }
        }
        var compare = function(a,b){
            var ret = 0;
            for(x =0; x< config.length;x++){
                if(config[x].select == true){
                    //console.log(a,b,config[x].name)
                    var index = header.indexOf(config[x].name);
                    if(a[index] < b[index]){
                        ret = -1
                    }
                    if(a[index] > b[index]){
                        ret = 1
                    }
                    if(ret!=0){
                        if(config[x].ascending == false){
                            ret *= -1;
                        }
                        return ret;
                    }
                    
                }
            }
            return ret
        }
        tmp.sort(compare);
        for (var i = 0; i < tmp.length; i++) {
            ret.push(tmp[i]);
        }        
        return ret;
    }else {
        return items;
    }

}

function createCSVFromRecords(records,delimiter=","){
    var ret  = convertArrayToCSV(records, {
        // header: records[0],
        separator: delimiter
    });
    //console.log(ret);
    return ret;
}

function processCSV(items, processName, config) {
    console.log("processCSV", processName, items.length,config);
    if(items.length ==0){
        return items;
    }
    var ret;
    if (processName == "TransformColumn") {
        ret = transformColumn(items, config, function (i, ex) {
            //err.push(i,ex);
            throw ex;
        })
        return ret;
    }
    if (processName == "CalculatedColumn") {
        ret = calculatedColumn(items, config, function (i, ex) {
            //err.push(i,ex);
            throw ex;
        })
        return ret;
    }
    if (processName == "SelectColumn"){
        ret = reorderColumn(items,config,function(i,ex){
            throw ex;
        })
        return ret;
    }
    if (processName == "SortByColumn"){
        ret = sortByColumn(items,config,function(i,ex){
            throw ex;
        })
        return ret;
    }
    if (processName == "QRCode"){
        ret = items;
        if(config.chkCreateNewColumn == true){
            ret = calculatedColumn(items,config,function(i,ex){
                throw ex;
            })
        }
        if(config.chkGenImage == true){
            if(isColumnExist(ret[0],config.imageColumn)==false){
                throw new CSVValidationError(`Image file name [${config.imageColumn}] not exist`);
            }
        }

        return ret;
    }    
    if (processName == "SendEmail"){
        ret = items;
        return ret;
    }    
    if (processName == "WalletPass"){
        if(config.copy == true){
            throw new CSVValidationError(`Copy pass, please check and save`);
        }
        var colindex = csvUtils.getColumnIndex(items[0],config.templateData.qrcodeColumn);
        if(colindex>=0){
            for(var i=1;i<items.length;i++){
                items[i][colindex] = "http://qrcodeLink.com";
            }
        }else{
            items[0].push(config.templateData.qrcodeColumn);
            for(var i=1;i<items.length;i++){
                items[i].push("http://qrcodeLink.com");
            }
        }
    }
    if (processName == "WalletPass (MindFire)"){
        if(config.copy == true){
            throw new CSVValidationError(`Copy pass, please check and save`);
        }
        items[0].push(config.templateData.qrcodeColumn);
        for(var i=1;i<items.length;i++){
            items[i].push("http://qrcodeLink.com");
        }
    }

    return items;
}

function readFileAsyncAsText(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    //reader.readAsArrayBuffer(file);
    reader.readAsText(file);
  })
}

var CSVBlobBuilder = function() {
     var blob = new Blob([], {type: 'text/csv'});
     this.append = function(src)
     {
         blob = new Blob([blob, src], {type: 'text/csv'});
     };
     this.getBlob = function()
     {
         return blob;
     }
};

export default {
    name: "Kwang-CSVProcessorView",
    components: {
        // KCCreateComponent,
        KCAddCSVProcessDialog,
    },
    props: {
        value: {
            default: false,
        },
        serverMode: {
            default: utils.getServerMode("mindfireOne"),
        },
        csvUrl: {
            default:"", 
        },
        readOnlyConfigItems: {
            default() {
                return [];
            }
        },
        readOnlyConfigName: {
            default:"",
        },

        readOnlyMode: {
            default:false,
        },

        previewData:{
            default:null,
        },
        experimentMode:{
            default:false,
        },
        replayMode: {
            default:false,
        },
        taskID: {
            default:null,
        },
        isStage: {
            default:false,
        } 
    },
    data() {
        var now = Date.now();
        var config = utils.getServerConfig(this.serverMode);
        //console.log(config);
        var endPoint =  config.endPoints.mindfireOne + "/api/csvprocessor";
        return {
            disablePlayTask: false,
            endPoint: endPoint,
            config:config,
            dialog: this.value,
            show: false,
            addDlg: false,
            window: {
                width: 0,
                height: 0,
            },
            headers: [],
            records: [],
            configHeader: [
                { text: "Process", value: "process", sortable: false},
                { text: "Detail", value: "detail", sortable: false, width:"350px"},
                //{ text: "Enable", value: "enable", sortable: false},
                { text: "Action", value: "action", sortable: false, align:"right"},
            ],
            configItems: [],
            testDataTable: {
                header: [],
                items: [],
            },
            // csvUrl: ,
            selectedConfig: {},
            selectedIndex: -1,
            localFileInput: undefined,
            // fileInputKey: 0,
            configName: this.readOnlyConfigName,
            addDlgReadOnly: this.readOnlyMode,
            addDlgUpdate: false,
            panels: [0],
            rawDataBlob: null,
            refreshKey: 0,  // to trink computed property to update
            delimiter: ",",
            fileExtension: ".csv",
        };
    },
    computed: {
        // hasRecords: function(){
        //     return this.records.length > 0;
        // },
        editIcon: function(){
            return  this.readOnlyMode?"remove_red_eye":"mode";
        },
        editToolTip: function(){
            if(this.replayMode) return "View";
            return  this.readOnlyMode?"View":"Edit";
        },

        effectiveHeaders: function () {
            var ret = this.computeEffectiveData();
            return ret[0];
        },
        effectiveData: function(){
            var ret = this.computeEffectiveData();
            return ret[1];
        },
        canSubmit: function(){
            // has data 
            this.refreshKey;    // just reference it to make dependeency; when first call can not reach all dependency. 
            //console.log("canSubmit",this.configItems);
            if((this.csvUrl!="")||(this.localFileInput!=undefined)){
                if(this.configItems.length > 0 && this.readOnlyMode == false){
                    // make sure no error
                    console.log("canSubmit",this.configItems);
                    for(var x in this.configItems){
                        var configItem = this.configItems[x];
                        if(configItem.error){
                            return false;
                        }
                        if(configItem.process == "WalletPass"){
                            let detail = JSON.parse(configItem.detail);
                            if(detail.copy == true){
                                return false;
                            }
                        }
                    }
                    return true;
                }
            }
            return false;
        },
        canReplay: function(){
            if(this.replayMode){
                if((this.csvUrl!="")||(this.localFileInput!=undefined)){
                    if(this.configItems.length > 0 && this.readOnlyMode == false){
                        // make sure no error
                        console.log("canReplay",this.configItems);
                        for(var x in this.configItems){
                            var configItem = this.configItems[x];
                            if(configItem.error){
                                return false;
                            }
                        }
                        return true;
                    }
                }
            }
            return false;
        },
        cookCSVUrl: function(){
            var url = this.csvUrl;
            var filename = url.substring(url.lastIndexOf('/')+1);
            return filename;
            
        },
        showPlusButton: function(){
            return this.readOnlyMode==false && (this.localFileInput || this.csvUrl != '')
        },
        showPencilButton: function(){
            console.log("showPencilButton",this.localFileInput,this.csvUrl);
            return (this.localFileInput || this.csvUrl != '')
        },
        isLocalSite: function(){
            return this.experimentMode && location.host.startsWith("localhost");
        },
        // readOnlyMode(){
        //     return this.readOnlyConfigItems.length != 0;
        // },
    },
    methods: {
        async addProcessClick() {
            this.addDlgReadOnly = this.readOnlyMode;
            this.addDlgUpdate = false;
            this.addDlg = true;
            this.selectedConfig = {};
            this.selectedIndex = -1;
        },
        computeEffectiveData(){
            console.log("computeEffectiveData selectedIndex=",this.selectedIndex);
            var limit = this.selectedIndex;
            if(limit < 0){
                limit = this.configItems.length;
            }
            //var ret = [];
            // ret = [... this.headers];
            var tmp = this.processCSVAllEx(this.records,null,2,limit);
            console.log("computeEffectiveData",tmp);
            return tmp;
        },
        downloadBlobToLocal(myBlob,defaultFileName = "demo.csv"){
            var url = window.URL.createObjectURL(myBlob);
            var anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = defaultFileName;
            // (E) "FORCE DOWNLOAD"
            // NOTE: MAY NOT ALWAYS WORK DUE TO BROWSER SECURITY
            // BETTER TO LET USERS CLICK ON THEIR OWN
            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
        },

        exportClick(){
            var records = this.processCSVAllEx(this.records,null);
            var csvData = createCSVFromRecords(records);
            var myBlob = new Blob([csvData], {type: "text/csv"});
            this.downloadBlobToLocal(myBlob);
        },

        clearConfigItem(){
            this.configItems = [];
        },

        _loadCSVFromString(csvData,delimiter){
            var result = Papa.parse(csvData, {} );    
            console.log("_loadCSVFromString",result);
            var records = result.data;
            if(records.length>1){
                // remove last low if not the same column
                // possible result from slice the csv text 
                if(records[records.length-1].length!=records[0].length){
                    records.pop();
                }
                this.delimiter = result.meta.delimiter;
                console.log("_loadCSVFromString","delimiter",this.delimiter);
            }


            console.log(records);
            return records;
        },

        correctHeaderCase(header){
            if(this.previewData && this.previewData.length > 0){
                //this.correctedHeader = null;
                let previewHeader = this.previewData[0];
                console.log("correctHeaderCase",header,previewHeader);
                for(let x in previewHeader){
                    let prevH = previewHeader[x];
                    if(csvUtils.getColumnIndex(header,prevH)<0){
                        let index = csvUtils.getColumnIndex(header,prevH,true);
                        if(index >=0){
                            // found incorrect case header
                            console.log("processPreviewData","correct case",header[index],"->",prevH);
                            header[index] = prevH;
                            //headerMapper.start[header[index],prevH];
                            //headerMapper.end[prevH,header];
                        }
                    }
                }
                console.log("correctHeaderCase","finalheder",header);
                this.correctedHeader = [... header];
            }
        },

        loadCSVFromString(csvData,delimiter){
            //console.log("csv",csvData,del);
            const records = this._loadCSVFromString(csvData,delimiter);
            if (records.length > 0) {
                this.correctHeaderCase(records[0]);
                console.log("loadCSVFromString","headers", this.headers);
                this.headers = records[0];
                this.records = records;
            }
        },
        async createClick() {
            this.show = true;
        },
        async getCSVBlobFromUrl(url){
            this.showProgressSwal('Downloading',null,false);
            var blob = null;
            //const span = this.$swal.getHtmlContainer().querySelector('span');
            var axiosOption = {
                onDownloadProgress: (progressEvent) => {
                    //var percentCompleted = ((progressEvent.loaded * 100)/progressEvent.total).toFixed(2);
                    //span.textContent = `${percentCompleted}%`;
                    this.updateProgressSwal(null,(progressEvent.loaded * 100)/progressEvent.total);
                },
            }
            const response = await this.getCleanAxios().get(url,{
                    responseType: "arraybuffer",
                    ... axiosOption
            });
            this.closeProgressSwal();
            console.log(response.data,typeof response.data);
            if(url.toLowerCase().endsWith(".zip")){
                console.log("Zip file");
                blob = await this.unZippingDataBlob(new Blob([response.data]));
            }else{
                blob = new Blob([response.data],{type: "text/csv"});
            }
           
            //this.$swal.close();
            return blob;
        },
        getCleanAxios(){
            let axiosInstance = axios.create();
            delete axiosInstance.defaults.headers.common['Authorization'];
            return axiosInstance;
        },
        async getCSVDataFromUrl(url,maxsize=-1){
            this.showProgressSwal('Downloading',null,false);
            // this.$swal.fire({
            //     title: 'Downloading',
            //     html: `<span></span>`,
            // });                
            // this.$swal.showLoading();
            var csvData="";
            this.rawDataBlob = null;
            //const span = this.$swal.getHtmlContainer().querySelector('span');
            var axiosOption = {
                onDownloadProgress: (progressEvent) => {
                    // var percentCompleted = ((progressEvent.loaded * 100)/progressEvent.total).toFixed(2);
                    // span.textContent = `${percentCompleted}%`;
                    this.updateProgressSwal(null,(progressEvent.loaded * 100)/progressEvent.total);
                },
            }
           
            if(url.toLowerCase().endsWith(".zip")){
                console.log("Zip file");
                const response = await this.getCleanAxios().get(url,{
                        responseType: "arraybuffer",
                        ... axiosOption
                });
                console.log(response.data,typeof response.data);
                this.rawDataBlob = await this.unZippingDataBlob(new Blob([response.data]));
                //csvData = await this.rawDataBlob.slice(0,maxsize).text();
                if(maxsize >0){
                    //csvData = csvData.substring(0,maxsize);
                    csvData = await this.rawDataBlob.slice(0,maxsize).text();
                }
                console.log(csvData.substring(0,maxSampleCSV));
            }else{
                var option = {};
                if(maxsize >0){
                    option = {
                        headers: {
                            Range: `bytes=0-${maxsize}`,
                        }
                    };
                }
                const response = await this.getCleanAxios().get(url,{
                    ... option,
                    ... axiosOption
                });
                csvData = response.data;
            }
            if(maxsize >0){
                console.log(csvData,csvData.length);
            }else{
                console.log("csvData.length",csvData.length);
            }
            //this.$swal.close();
            this.closeProgressSwal();
            return csvData;
        },
        async loadCSV() {
            if(this.csvUrl!=""){
                if(this.previewData!=null){
                    var records = this.previewData;
                    this.headers = records[0];
                    this.records = records;
                    this.correctedHeader = this.headers;
                }else{
                    var csvData = await this.getCSVDataFromUrl(this.csvUrl,maxSampleCSV);
                    var del = csvDetectDelimiter(csvData);
                    this.loadCSVFromString(csvData,del);
                }
            }
        },

        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.height = Math.max(400, (this.window.height - 100) / 2);
        },
        dlgClose(param){
            console.log("dlgClose", this.$refs.tooltipEdit);
        },
        addCommit(param) {
            console.log("addCommit", param);
            if (this.selectedConfig.length == undefined) {
                this.configItems.push({
                    process: param[0],
                    detail: JSON.stringify(param[1]),
                    enable: true,
                });
            } else {
                console.log("Update mode");
                var configItems = [];
                for (var x in this.configItems) {
                    if (x == this.selectedIndex) {
                        var prevEnable = this.configItems[this.selectedIndex].enable;
                        configItems.push({
                            process: param[0],
                            detail: JSON.stringify(param[1]),
                            enable: prevEnable,
                        });
                    } else {
                        configItems.push(this.configItems[x]);
                    }
                }
                this.configItems = configItems;
            }
        },
        doAction(type, param) {
            console.log("doAction", type, param);
            if (type == "delete") {
                console.log(param);
                var index = param.index;
                this.configItems.splice(index, 1);
            }
            if (type =="view"){
                var detail = JSON.parse(param.item.detail);
                if(param.item.process == "QRCode"){
                    if(detail.chkCreateNewColumn == undefined){
                        detail.chkCreateNewColumn = true;
                    }
                    if(detail.chkEvalMode == undefined){
                        detail.chkEvalMode = true;
                    }
                }
                var config = [
                    param.item.process,
                    detail,
                ]
                this.selectedConfig = config;
                this.selectedIndex = param.index;
                this.addDlgReadOnly = true;
                this.addDlgUpdate = false;
                this.addDlg = true;                    
            }
            if (type == "edit") {
                var detail = JSON.parse(param.item.detail);
                if(param.item.process == "QRCode"){
                    if(detail.chkCreateNewColumn == undefined){
                        detail.chkCreateNewColumn = true;
                    }
                    if(detail.chkEvalMode == undefined){
                        detail.chkEvalMode = true;
                    }
                }
                var config = [
                    param.item.process,
                    detail,
                ]
                this.selectedConfig = config;
                this.selectedIndex = param.index;
                this.addDlgReadOnly = this.readOnlyMode;
                this.addDlgUpdate = false;
                if(param.item.process == "WalletPass"){
                    //this.addDlgReadOnly = false;
                    if(param.item.error){

                    }else{
                        this.addDlgUpdate = true;
                    }
                }
                this.addDlg = true;                    
            }
            if (type == "up") {
                utils.arraymove(this.configItems, param.index, param.index - 1);
            }
            if (type == "down") {
                utils.arraymove(this.configItems, param.index, param.index + 1);
            }
        },
        createTestDatableData(records) {
            var i;
            var headers = [];
            var items = [];
            if (records.length > 0) {
                for (i = 0; i < records[0].length; i++) {
                    headers.push({ text: records[0][i], value: "" + i,sortable: false });
                }
                for (i = 1; i < records.length; i++) {
                    var item = {};
                    for (var x = 0; x < records[i].length; x++) {
                        item["" + x] = records[i][x];
                    }
                    items.push(item)
                }
            }
            return { headers: headers, items: items }
        },

        // processCSVAll(_records, limit = Number.MAX_SAFE_INTEGER,configLimit = Number.MAX_SAFE_INTEGER){
        //     var records = []
        //     var num = Math.min(_records.length,limit);
        //     if(num != _records.length){
        //         for (var i =0;i<num;i++) {
        //             records.push([... _records[i]])
        //         }
        //     }else{
        //         records = _records;
        //     }
        //     var numConfig = Math.min(this.configItems.length,configLimit);
        //     for (i = 0; i < numConfig; i++) {
        //         var configItem = this.configItems[i];
        //         if (configItem.enable == true) {
        //             try {
        //                 records = processCSV(records, configItem.process, JSON.parse(configItem.detail));
        //             } catch (ex) {
        //                 if (ex instanceof ReferenceError) {
        //                     console.error(ex.message);
        //                     configItem.error = this.cookErrorMessage(ex.message);
        //                 } else {
        //                     console.error(ex);
        //                 }
        //             }
        //         } else {
        //             delete configItem.error;
        //         }
        //     }
        //     return records;
        // },

        processCSVAllEx(_records, option, limit = Number.MAX_SAFE_INTEGER,configLimit = Number.MAX_SAFE_INTEGER){
            var records = []
            var num = Math.min(_records.length,limit);
            if(num != _records.length){
                for (var i =0;i<num;i++) {
                    records.push([... _records[i]])
                }
            }else{
                records = _records;
            }
            var numConfig = Math.min(this.configItems.length,configLimit);
            for (i = 0; i < numConfig; i++) {
                var configItem = this.configItems[i];
                if (configItem.enable == true) {
                    if(option){
                        if("only" in option){
                            if(option.only.indexOf(configItem.process)<0){
                                //console.log("Skip",configItem.process,"by only",option.only);
                                continue;
                            }
                        }
                        if("skip" in option){
                            if(option.skip.indexOf(configItem.process)>=0){
                                //console.log("Skip",configItem.process,"by skip",option.skip);
                                continue;
                            }
                        }
                    }
                    try {
                        records = processCSV(records, configItem.process, JSON.parse(configItem.detail));
                    } catch (ex) {
                        if (ex instanceof ReferenceError) {
                            console.error(ex.message);
                            configItem.error = this.cookErrorMessage(ex.message);
                        } else if (ex instanceof CSVValidationError){
                            console.error(ex.message);
                            configItem.error = ex.message;
                        }else{
                            console.error(ex);
                        }
                    }
                } else {
                    delete configItem.error;
                }
            }
            return records;
        },        

        validateConfigAndData(){

        },

        processPreviewData() {
            // check header 
           
            var records = this.processCSVAllEx(this.records,null,5);
            this.newPreviewData = [... this.records];
            this.testDataTable = this.createTestDatableData(records);
            // force update 
            this.refreshKey++;
        },

        cookErrorMessage(mesg) {
            if (mesg.indexOf("is not defined") > 0) {
                if (mesg[0] == "$") {
                    mesg = mesg.replaceAll("$", "Column");
                }
            }
            return mesg;
        },

        itemRowBackground(item) {
            if(this.replayMode){
                return "";
            }
            if (item.error) {
                return "script-error";
            }
        },
        async getZip(){
            for(let i =0;i<10;i++){
                if(zip != undefined){
                    break;
                }
                console.log("getZip","wait",i+1);
                await utils.asyncSleep(500);
            }
            return zip;
        },

        async unZippingDataBlob(blob){
            var zip = await this.getZip();
            var showSwalSize = 10*1000000;
            //const zipFileReader = new BlobReader(blob);
            const zipFileReader = new zip.BlobReader(blob);
            console.log(zipFileReader);
            const zipReader = new zip.ZipReader(zipFileReader);
            const firstEntry = (await zipReader.getEntries()).shift();
            console.log(firstEntry);
            var shouldShowSwal =firstEntry.uncompressedSize > showSwalSize;
            if(shouldShowSwal){
                this.showProgressSwal(`Unzipping: ${(firstEntry.uncompressedSize/1000000).toFixed(2)}M`,null,false);
                // this.$swal.fire({
                //     title: 'Unzipping',
                //     html: `<span>File size: ${(firstEntry.uncompressedSize/1000000).toFixed(2)}M</span><br><span class='progress'></span>`,
                // });                
                // this.$swal.showLoading();
            }
            //const textWriter = new TextWriter();
            const b = new zip.BlobWriter("text/csv");
            var ret = await firstEntry.getData(b,{
                onprogress: (progress, total)=>{
                    if(shouldShowSwal){
                        //var span = this.$swal.getHtmlContainer().querySelector('span.progress');
                        //span.textContent = `${(progress*100/total).toFixed(2)}%`;
                        this.updateProgressSwal(null,progress*100/total);
                    }
                    //console.log("unZippingDataBlobWithLimit","onprogress",`${progress}/${total}`);
                },               
            });
            await zipReader.close();
            if(shouldShowSwal){
                //this.$swal.close();
                this.closeProgressSwal();
            }
            console.log(ret);
            return ret;
        },
        
        async loadFromLocal() {
            if (this.localFileInput) {
                this.rawDataBlob = null;
                if(this.localFileInput.name.toLowerCase().endsWith(".zip")){
                    console.log("Zipfile found");
                    // var blob = await this.unZippingDataBlob(this.localFileInput);
                    // this.rawDataBlob = blob;
                    // this.data = await blob.slice(0,1024*5).text();
                    // console.log(this.data,this.data.length);
                    let info = {};
                    this.data = await this.unZippingDataBlobWithLimit(this.localFileInput,info);
                    console.log(info);
                    this.data = this.data.substring(0,1024*5);
                    this.loadCSVFromString(this.data);
                    this.processPreviewData();
                }else{
                    this.fileExtension = utils.pathInfo(this.localFileInput.name).ext;
                    console.log("fileExtension",this.fileExtension);
                    var reader = new FileReader();
                    // using file.slice command to slice the file to blob at first 5k
                    // so we read just 5 k instead of whole file
                    let blob = this.localFileInput.slice(0,1024*5);
                    console.log(blob);
                    //reader.readAsText(this.localFileInput);
                    reader.readAsText(blob);
                    reader.onload = () => {
                        this.data = reader.result;
                        console.log(this.data,this.data.length);
                        this.loadCSVFromString(this.data);
                        this.processPreviewData();
                    }
                }
            }
        },

        async checkJSON(e) {
            //clear the local file
            console.log("checkJSON",e); // e == this.localFileInput
            this.headers = [];
            this.records = [];
            if(e == null){
                // clear
                return;
            }
            if (!allowedExtensions.exec(e.name)) {
                //alert("Invalid file type");
                //this.localFileInput = undefined;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid file type',
                    ... csvUtils.getSwalYesNoOption(),
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                
                return 
            }
            // clear error;
            for(var x in this.configItems){
                delete this.configItems[x].error;
            }
            try{
                await this.loadFromLocal();
                if(this.replayMode){
                    // this.value = false;
                    //this.readOnlyMode = true;
                }
            }catch(ex){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: ex.message,
                    ... csvUtils.getSwalYesNoOption(),
                    confirmButtonText: "Ok",
                })
                this.$refs.localFileInput.reset();
                this.localFileInput  = null;
                console.error(ex);
            }
        },

        csvRecordToCsvBlob(records,recordPerBlob=1000){
            var blobs = [];
            while(records.length>0){
                var tmps = records.splice(0,recordPerBlob);
                var tmpCsvData = createCSVFromRecords(tmps);
                blobs.push(tmpCsvData);
                console.log(tmps.length);                
            }
            var retBlob = new Blob(blobs, {type: "text/csv"});
            return retBlob;
        },

        *csvRecordToCsvBlobGen(records,recordPerBlob=1000){
            var blobs = [];
            while(records.length>0){
                var tmps = records.splice(0,recordPerBlob);
                var tmpCsvData = createCSVFromRecords(tmps);
                blobs.push(tmpCsvData);
                console.log(tmps.length);                
                yield tmps.length
            }
            var retBlob = new Blob(blobs, {type: "text/csv"});
            //return retBlob;
            yield retBlob
        },

        csvRecordToCsvBlobAsync(records,callback,recordPerBlob=1000){
            return new  Promise((resolve, reject) => {
                var gen = this.csvRecordToCsvBlobGen(records,recordPerBlob);
                var intv = setInterval(()=>{
                    var v = gen.next();
                    if(v.done==true){
                        clearInterval(intv);
                    }else{
                        if(typeof v.value!= "number"){
                            resolve(v.value)
                        }else{
                            if(callback){
                                callback(v.value);
                            }
                        }
                    }
                }, 100);
            });
        },

        // unzip first file in zip return just data from the first cnunk 
        async unZippingDataBlobWithLimit(blob,info){
            var zip = await this.getZip();
            var stringResult = "";
            const writer = {        // custom text writer to keep ongoin result before abort 
                textDecoder: new TextDecoder(),
                writable: new WritableStream({
                    write(chunk) {
                        stringResult += writer.textDecoder.decode(chunk)
                        //console.log("writer",str.length,str);
                    },
                    close() {
                        //writerClosed = true;
                    }
                })
            };

            //configure({ chunkSize: 10000, useWebWorkers: false });
            //const zipFileReader = new BlobReader(blob);
            const zipFileReader = new zip.BlobReader(blob);
            const zipReader = new zip.ZipReader(zipFileReader);
            const firstEntry = (await zipReader.getEntries()).shift();
            console.log("unZippingDataBlobWithLimit",firstEntry.filename);
            
            if (!allowedExtensions.exec(firstEntry.filename)) {
            //if(firstEntry.filename.toLowerCase().endsWith(".csv")==false){
                throw new Error(`First file in zip (${firstEntry.filename})  is not .csv or .txt.`);
            }
            this.fileExtension = utils.pathInfo(firstEntry.filename).ext;
            console.log("fileExtension",this.fileExtension);
            // create controller and signal for aboring
            const controller = new AbortController();
            const signal = controller.signal;
            try{
            var ret = await firstEntry.getData(writer,{
                onstart: (total)=>{
                },
                onprogress: (progress, total)=>{
                    console.log("unZippingDataBlobWithLimit","onprogress",`${progress}/${total}`);
                    if(info){
                        info.totalSize = total;
                    }
                    controller.abort();
                },
                onend: (computedSize)=>{
                },
                signal,
            });
            }catch(ex){
                // will have error here from abort just ignore it
                //console.log(ex);
            }
            await zipReader.close();
            //console.log(stringResult);
            if(info){
                info.sampleSize = stringResult.length;
            }
            return stringResult;
        },

        showProgressSwal(title,callback,showCancelButton = true){
            var html = ""
            +'<div class="container" style="padding:0px;">'
            +   '<div style="padding-bottom:5px;"><span class="progress"></span></div>'
            +    '<div class="meter">'
            +        '<span style="width: 0%"></span>'
            +    '</div>'
            +'</div>';
            this.$swal.fire({
                title: title,
                html: html,
                showCancelButton: showCancelButton,
                showConfirmButton: false,
            }).then((result)=>{
                if(callback){
                    callback(result);
                }
            });
        },
        async updateProgressSwal(title,progressPercentage){
            if(title){
                this.$swal.getTitle().textContent = title;
            }
            var span = this.$swal.getHtmlContainer().querySelector('span.progress');
            span.textContent = `${progressPercentage.toFixed(2)}%`;
            var meter = this.$swal.getHtmlContainer().querySelector('div.meter > span');
            meter.style.width = `${progressPercentage.toFixed(2)}%`;
        },
        async closeProgressSwal(){
            this.$swal.close();
        },

        async checkZipFromUrl(url){
            const response = await this.getCleanAxios().get(url,{
                    responseType: "arraybuffer",
            });
            if(url.toLowerCase().endsWith(".zip")){
                console.log("Zip file");
                var blob = await this.unZippingDataBlob(new Blob([response.data]));
                console.log(blob);
                return true;
            }
            return false;
        },

        async testStream(){
            var url = "https://mfdavinci.s3.us-west-1.amazonaws.com/file/qrcode/159-20001.zip";

            const response = await this.getCleanAxios().get(url,{
                    responseType: "arraybuffer",
            });
            console.log(response.data,typeof response.data);
            if(url.toLowerCase().endsWith(".zip")){
                console.log("Zip file");
                var blob = await this.unZippingDataBlob(new Blob([response.data]));
                console.log(blob);
            }
            
            // var ret = await this.uploadFileToS3Stream(this.localFileInput);
            // console.log(ret);
        },

        async localProcessCSV(){
            var blob;
            var name;
            if(this.csvUrl!=""){
                name = this.cookCSVUrl;
                console.log("localProcessCSV","from url",this.csvUrl,name);
                if(this.rawDataBlob==null){
                    console.log("localProcessCSV","need to download");
                    this.rawDataBlob = await this.getCSVBlobFromUrl(this.csvUrl);
                }
                console.log("localProcessCSV","using rawDataBlob")
                blob = this.rawDataBlob;
                name = name.replace(".zip",".csv");
            }
            if(this.localFileInput){
                name = this.localFileInput.name;
                //has local file
                console.log("localProcessCSV",this.localFileInput);
                blob = this.localFileInput;
                if(this.localFileInput.name.toLowerCase().endsWith(".zip")){
                    this.rawDataBlob = await this.unZippingDataBlob(this.localFileInput);
                    // this.rawDataBlob = blob;
                    //console.log("Zipfile found use rawDataBlob");                
                    blob = this.rawDataBlob;
                    name = name.replace(".zip",".csv");
                }
            }
            if(blob){                
                var header;
                var cancel = false;
                this.showProgressSwal('Processing',(result)=>{
                    console.log("showProgressSwal",result);
                    if(result.dismiss == 'cancel'){
                        cancel = true;
                    }
                });

                var csvBlobBuilder = new CSVBlobBuilder();
                var promise = new  Promise((resolve, reject) => {
                    var recordCount = 0;
                    var first = false;
                    Papa.parse(blob, {
                        worker: true,
                        //header: true,
                        chunkSize: 10000000,
                        skipEmptyLines: true,
                        chunk: function(results,parser) {
                            if(cancel == true){
                                console.log("abort");
                                parser.abort();
                            }
                            var records = results.data;
                            if(first == false){
                                header = results.data[0];
                                if(this.correctedHeader){
                                    console.log("use header from correctedHeader",header,this.correctedHeader);
                                    header = this.correctedHeader;
                                    records[0] = header;
                                }
                            }else{
                                records.unshift(header);
                            }
                            this.updateProgressSwal(null,results.meta.cursor*100/blob.size);
                            var out_records = this.processCSVAllEx(records,{skip:["SortByColumn"]});
                            if(first == false){
                                first = true;
                            }else{
                                // remove header
                                out_records = out_records.splice(1);
                            }
                            recordCount += out_records.length;
                            var tmpCsvData = createCSVFromRecords(out_records);
                            csvBlobBuilder.append(tmpCsvData);
                        }.bind(this),
                        complete: function( results ){
                            console.log("complete",recordCount);
                            resolve(true);
                        }.bind(this),
                    } );
                });
                var start = Date.now();
                var records = await promise;
                console.log("records",records);
                var end = Date.now();
                this.closeProgressSwal();
                //this.$swal.close();
                if(cancel == false){
                    // does not handle sort
                    this.showProgressSwal('Compressing',(result)=>{
                        console.log("showProgressSwal",result);
                        if(result.dismiss == 'cancel'){
                            cancel = true;
                        }
                    });                    
                    var zipBlob = await this.zipBlob(csvBlobBuilder.getBlob(),name,
                        (type,progress,total) => {
                            if(type == "onprogress"){
                                this.updateProgressSwal(null,progress*100/total);
                                if(cancel == true){
                                    return true;
                                }
                            }
                            return false;
                        }
                    );
                    this.closeProgressSwal();
                    await utils.asyncSleep(50);
                    console.log("cancel",cancel,zipBlob);
                    if(cancel==false){
                        if(this.isLocalSite){        
                            console.log("downloadBlobToLocal");            
                            this.downloadBlobToLocal(zipBlob,"download.zip");
                            console.log("DEBUG");
                        }            
                        console.log("zipBlob",zipBlob);    
                        return zipBlob;
                    }
                }
                console.log("Cancel by user");
                return null;
            }
        },
        async localProcessCSVX(){
            var zip = await this.getZip();
            const xxx = new zip.BlobWriter("text/csv");
            console.log(xxx);
            await xxx.writeUint8Array(new TextEncoder().encode("123"));
            
        },
        async uploadFileToS3WithSwal(e){
            this.showProgressSwal('Prepare for uploading',(result)=>{
            },false);                 
            // this.$swal.fire({
            //     title: 'Prepare for uploading',
            //     html: '<span></span>',
            // });                
            // this.$swal.showLoading();
            //const b = this.$swal.getHtmlContainer().querySelector('span');
            var url = await this.uploadFileToS3(e,(param)=>{
                if("title" in param){
                    this.updateProgressSwal(param.title,0);
                    // this.$swal.getTitle().textContent = param.title;
                    // b.textContent = "";
                }
                if("progressEvent" in param){
                    var progressEvent = param.progressEvent;
                    //this.$swal.getTitle().textContent = "Uploading";
                    //b.textContent = `Complete ${(progressEvent.loaded*100/progressEvent.total).toFixed(2)}%` ;
                    this.updateProgressSwal("Uploading",progressEvent.loaded*100/progressEvent.total);
                }
                if("type" in param){
                    // this.$swal.getTitle().textContent = "Compressing";
                    // b.textContent = `Complete ${(param.progress*100/param.total).toFixed(2)}%` ;
                    //this.$swal.getTitle().textContent = "Compressing";
                    this.updateProgressSwal("Compressing",param.progress*100/param.total);

                }
            });
            //this.$swal.close();
            this.closeProgressSwal();
            return url;
        },

        async zipBlob(blob,name,callback){
            var zip = await this.getZip();
            const zipFileWriter = new zip.BlobWriter("application/zip");
            const zipFileReader = new zip.BlobReader(blob);
            const zipWriter = new zip.ZipWriter(zipFileWriter);
            const controller = new AbortController();
            const signal = controller.signal;            
            try{
                await zipWriter.add(name, zipFileReader,{
                    onprogress: (progress, total)=>{
                        if(callback){
                            let sholdCancel =  callback("onprogress",progress,total);
                            if(sholdCancel){
                                    console.log("zipBlob","try abort");
                                    controller.abort()
                            }
                        }
                    },   
                    signal
                });
            }catch(ex){
                console.log("zipBlob","abort by user",ex);
                return null;
            }
            await zipWriter.close();
            var ret = await zipFileWriter.getData();
            return ret;
        },

        async uploadFileToS3(e,progressCallbak){
            // var e = this.localFileInput;
            var type = "application/zip";
            var blob = e;
            var suffix = type.split("/")[1];
           
            console.log("uploadFileToS3",e);
            if(e.type == "text/csv" || e.type == "text/plain"){
                console.log("zip it");
                blob = await this.zipBlob(e,e.name,
                (type,progress,total) => {
                    if(progressCallbak){
                        progressCallbak({
                            type,progress,total
                        });                
                    }
                });
                console.log(blob);
// console.log("debug return");
// return;
            }
            var presignedUrl = await this.getPreSignedURL(type,suffix);
            console.log("presignedUrl",presignedUrl);
            // create new axios and remove default Authorization header
            //let axiosInstance = axios.create();
            //delete axiosInstance.defaults.headers.common['Authorization'];
            var ret = await this.getCleanAxios().put(presignedUrl, blob, {
                headers: {
                    'Content-Type': type,
                    'x-amz-acl': 'public-read',
                },
                onUploadProgress: progressEvent => {
                    //console.log(progressEvent)
                    if(progressCallbak){
                        progressCallbak({
                            progressEvent: progressEvent,
                        })
                    }
                },
            });       
            var url = presignedUrl.split('?')[0];
            console.log(ret,url);
            return url;
        },
        async playTask(id){
// if(id){            
//     alert("DEBUG disable playTask");
//     return;
// }
            try{  
                var resp = await axios.get(`${this.endPoint}?cmd=chainExecute&id=${id}`);
                return resp.data.result;
            }catch(ex){
                console.log(ex);
            }     
        },
        isLocalComplete(configItems){
            for(var i=0;i<configItems.length;i++){
                var configItem = configItems[i];
                if(!configItem.enable){
                    continue;
                }
                if(configItem.process == "QRCode"){
                    var detail = JSON.parse(configItem.detail);
                    if(detail.chkGenImage == true){
                        return false;
                    }
                }
                if(configItem.process == "SendEmail"){
                    return false;
                }
            }
            return true;
        },
        isHasSortCommand(configItems){
            for(var i=0;i<configItems.length;i++){
                var configItem = configItems[i];
                if(!configItem.enable){
                    continue;
                }
                if(configItem.process == "SortByColumn"){
                    return true;
                }
            }
            return false;
        },
        autoAddSendEmail(){
            let found = false;
            for(var x in this.configItems){
                let configItem = this.configItems[x];
                console.log(configItem);
                if(configItem.process == "SendEmail"){
                    found = true;
                }
            }
            console.log("autoAddSendEmail","found SendEmail=",found);
            if(found == false){
                this.configItems.push({
                    process: "SendEmail",
                    detail: JSON.stringify(
                        {email:this.$store.getters.user.email},
                    ),
                    enable: true,
                });
            }
        },
        async submitTask(url,source,local,parentTask){
            console.log("submitTask",{
                csvUrl: url,
                configItems: this.configItems,
                source,local,
            })
            var accountID = this.$store.getters.user.account;
            var user = this.$store.getters.user.email;
            try{
                let prop =  {
                        source: source,
                        user: user,
                        localProcess: local,
                        previewData: this.newPreviewData,
                        correctedHeader: this.correctedHeader,
                        delimiter: this.delimiter,
                        fileExtension: this.fileExtension,
                        isStage: this.isStage,
                };
                if(parentTask!=null){
                    prop.parent = parentTask;
                }
                var resp = await axios.post(this.endPoint,{
                    cmd:"addStatus",
                    "url": url,
                    "prop": prop,
                    "acctID": accountID,
                    "user": user,
                    "name": this.configName,
                    "configItems": this.configItems,
                });
                if(resp.data.success == true){
                    //check local complete
                    var insertID = resp.data.result.insertId;
                    console.log("id=",insertID);
                    this.dialog = false;

                    var localComplete = false;
                    if(local == true){
                        localComplete = this.isLocalComplete(this.configItems);
                    }
                    if(localComplete){
                        console.log("local complete updateStatus");
                        await axios.post(this.endPoint,{
                            cmd:"updateStatus",
                            "acctID": accountID,
                            "user": user,
                            "id":insertID,
                            "status": "E",
                            "result": JSON.stringify({csv:url}),
                        });
                    }else{
                        this.$emit('submit',{id:insertID,parent:parentTask});
                        if(this.disablePlayTask == false){
                            await this.playTask(insertID);
                        }
                    }
                    // this will trick view to reload
                    this.$emit('submit',{id:insertID,parent:parentTask});
                }else{
                    console.error(resp.data);
                }
            }catch(ex){
                console.log(ex);
            }                    
        },
        async replayCSVConfig(){
            console.log("replayCSVConfig");
            var url = this.csvUrl;
            var source = url;
            if(this.localFileInput){
                source = this.localFileInput.name;
            }            
            if(url==""){
                console.log("Uploading local file to S3");
                // save from local file
                if(this.localFileInput){
                    url = await this.uploadFileToS3WithSwal(this.localFileInput);                    
                }
            }
            //check header 
            // console.log(replayCSVConfig,"headers",this.headers);
            // console.log(replayCSVConfig,"newPreviewData",this.newPreviewData);
            // console.log(replayCSVConfig,"correctedHeader",this.correctedHeader);
            await this.submitTask(url,source,false,this.taskID);
        },
        async saveCSVConfig(local){
            console.log("saveCSVConfig");
            // check for sendEmail
            this.autoAddSendEmail();

            //check if we have sort command then force to local
            if((local == false) && this.isHasSortCommand(this.configItems)){
                console.log("has sort commad force local mode");
                local = true;
            }

            var url = this.csvUrl;
            var source = url;
            if(this.localFileInput){
                source = this.localFileInput.name;
            }            
            if(local){
                var blob = await this.localProcessCSV();
                if(blob==null){
                    return;
                }
                url = await this.uploadFileToS3WithSwal(blob);  
            }else{
                if(url==""){
                    console.log("Uploading local file to S3");
                    // save from local file
                    if(this.localFileInput){
                        url = await this.uploadFileToS3WithSwal(this.localFileInput);                    
                    }
                }
            }
            await this.submitTask(url,source,local,null);
            // console.log({
            //     csvUrl: url,
            //     configItems: this.configItems,
            // })
            // var accountID = this.$store.getters.user.account;
            // var user = this.$store.getters.user.email;
            // try{  
            //     var resp = await axios.post(this.endPoint,{
            //         cmd:"addStatus",
            //         "url": url,
            //         "prop": {
            //             source: source,
            //             user: user,
            //             localProcess: local,
            //             previewData: this.newPreviewData,
            //             correctedHeader: this.correctedHeader,
            //             delimiter: this.delimiter,
            //             fileExtension: this.fileExtension,
            //         },
            //         "acctID": accountID,
            //         "user": user,
            //         "name": this.configName,
            //         "configItems": this.configItems,
            //     });
            //     if(resp.data.success == true){
            //         //check local complete
            //         var insertID = resp.data.result.insertId;
            //         console.log("id=",insertID);
            //         this.dialog = false;

            //         var localComplete = false;
            //         if(local == true){
            //             localComplete = this.isLocalComplete(this.configItems);
            //         }
            //         if(localComplete){
            //             console.log("local complete updateStatus");
            //             await axios.post(this.endPoint,{
            //                 cmd:"updateStatus",
            //                 "acctID": accountID,
            //                 "user": user,
            //                 "id":insertID,
            //                 "status": "E",
            //                 "result": JSON.stringify({csv:url}),
            //             });
            //         }else{
            //             this.$emit('submit',{id:insertID});
            //             if(this.disablePlayTask == false){
            //                 await this.playTask(insertID);
            //             }
            //         }
            //         // this will trick view to reload
            //         this.$emit('submit',{id:insertID});
            //     }else{
            //         console.error(resp.data);
            //     }
            // }catch(ex){
            //     console.log(ex);
            // }                        
        },
        async getPreSignedURL(type,suffix){
            try{  
                var resp = await axios.get(`${this.endPoint}?cmd=presignedurl&contentType=${type}&suffix=${suffix}`);
                console.log(resp);
                // this.dialog = false;
                // this.$emit('submit',{id:insertID});
                // run now 
                return resp.data.result;
            }catch(ex){
                console.log(ex);
            }                   

        },   
        async closeClick(){
            if(this.readOnlyMode==false && this.replayMode==false){
                if(this.canSubmit == true){
                    var result = await this.$swal.fire({
                        title: 'Are you sure you want to close this function without saving your work?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        // confirmButtonColor: '#3085d6',
                        // cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes',
                        // cancelButtonText: 'No',
                        ... csvUtils.getSwalYesNoOption()
                    });
                    console.log(result);
                    if (result.isConfirmed) {
                        // await this.deleteTaskStatus(Id);
                        // await this.loadTaskStatus();
                        this.dialog = false;
                    }   
                } else{
                    this.dialog = false;    
                }
            }else{
                this.dialog = false;
            }        
        },
        // gt(a,b){return a > b;},
        // ge(a,b){
        //     if(a > b) return true;
        //     if(a === b) return true;
        //     return false;
        // },
        scriptLoaded,    
    },

    created() {
        //console.log("mounted", this.$store);
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        //await this.setThePortalSettings();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    async mounted() {
        if (process.env.VUE_APP_JWT == "mockup") {
            utils.log("CSVProcessorView", "debug mode");
            //axios.defaults.headers.common['Authorization'] = "bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcCI6IjQ5LjIyOC4xMDIuMTE4IiwiZW1haWwiOiJrZHV0dGFAbWluZGZpcmVpbmMuY29tIiwicHUiOnRydWUsImF1Ijp0cnVlLCJwYSI6ZmFsc2UsImFpZCI6MzQzMDcsInBhaWQiOjE3NzIzLCJpc3MiOiJpZGVudGl0eS5tZGwuaW8iLCJpYXQiOjE2NDk2NjYxMjMsImV4cCI6MTY0OTY5NDkyM30.Z8bZvKeSg1I8L31F1LQY9AeEZu8gG3jYkzLAuOUQnn5lWjJmtb8Vqm3dPRbzhQKDYxqDyduCGoJUZ1AKkPH99K9qEZjFJdLzI_AoK6SHVjaBxC8PmKsm360-mNmsffbFCMZx7DVYwAuljmjNT-hKT3g-1mORLuSajgkvxz2M8KuGoZFyFvxRVKroQLBnVNE_YBJ64TZFZaBB0gDK9bVr-4pXz_wML2rxUbj9dL7L9ZOT1ZzbySpaS8zl7zx27PGdtWJQtNMTH5DGaH5Gx0gGRQOdJBQ9Oarb7OIRqnrD-rPr00Xl6rgght5l8Bedlz8ai2Vvmly6d-CJ0iifk9xHAQ";
        }
        //await this.setThePortalSettings();
    },
    beforeUnmount() { },
    watch: {
        configItems: {
            deep: true,
            handler(newValue, oldValue) {
                console.log("configItems changed");
                this.processPreviewData();
            },
        },
        // previewData: {
        //     handler: function(newValue){
        //         console.log("CSVProcessorView previewData change",newValue);
        //     }
        // },
        value: {
            handler: function(newValue, oldValue) {
                console.log("CSVProcessorView value change",newValue,this.config,this.readOnlyConfigItems,this.previewData);
                this.dialog = newValue;
                if(newValue == true){
                    // can not use await in watch so just do it as call back to make sure that 
                    // configItems change will trigger after we have data
                    this.loadCSV().then(()=>
                        {
                            this.configItems = [... this.readOnlyConfigItems];    
                            if(this.readOnlyMode){
                                for(var x in this.configItems){
                                    this.configItems[x].disabled = true;
                                }
                                this.configName = this.readOnlyConfigName;
                            }else{
                                //create default name
                                this.configName = "New-Process-" + utils.UTCDateTime();
                            }
                            this.processPreviewData();
                        }
                    )
                }else{
                    // clear internal variable
                    this.localFileInput = undefined;
                    this.records = [];
                    this.correctedHeader = null;
                    this.testDataTable = {
                        header: [],
                        items: [],
                    };
                }            
            },            
        },
        csvUrl(newValue,oldValue){
            // console.log("csvUrl change",oldValue,"->",newValue,)
            // if(newValue!=""){
            //     this.loadCSV();
            // }
        },
        dialog(newValue, oldValue) {
            this.$emit("input", newValue); // v-model need this to tell parent that value have change
        },
    },
};
</script>