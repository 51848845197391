<template>
<v-container class="kc-csv-processor-view px-lg-7 pt-lg-0" style="height:100%;max-width:100%;width:100%;">
    <!--<v-btn fab absolute @click="getAccountOverviewData()">xxxx</v-btn>-->
    <!--XXX {{kpi1.title1    }} YYY-->
    <!-- {{expanded}} -->
    <!-- <v-row>
        <v-col>
            {{tooltip}}
        </v-col>
    </v-row> -->
    <v-row class="mt-0">
        <v-col class="pt-5" cols=10>
            <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
                <span><v-icon>qr_code</v-icon></span>
                <span style="vertical-align: middle;" class="pl-1">CSV Processor Status
                    <!-- <KCSearchableSelect @select="searchableSelect" attachID="btn" v-model="menu"></KCSearchableSelect> -->
                </span>
            </h1>
        </v-col>
        <v-col cols=2 class="text-right">
            <v-tooltip top>
                <template v-slot:activator="{ on}">
                    <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="refreshTaskClick()">                        
                        <v-icon>
                            refresh
                        </v-icon>
                    </v-btn>
                </template>
                Reload tasks
            </v-tooltip>             
            <v-tooltip v-if="experimentMode==true" top>
                <template v-slot:activator="{ on}">
                    <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="addNewTaskClick(true)">                        
                        <v-icon>
                            add
                        </v-icon>
                    </v-btn>
                </template>
                Create new Task By URL
            </v-tooltip>                        
            <v-tooltip top>
                <template v-slot:activator="{ on}">
                    <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="addNewTaskClick(false)">                        
                        <v-icon>
                            add
                        </v-icon>
                    </v-btn>
                </template>
                Create new Task
            </v-tooltip>                        
        </v-col>
    </v-row>    
    <v-row class="py-1">
        <v-col cols=12>
            <v-data-table dense style="width=100%;" :headers="statusHeader" :items="statusItems" :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :item-class="itemRowBackground" :items-per-page="10" :expanded.sync="expanded" :single-expand="singleExpand" item-key="Id" @click:row="rowClick" class="elevation-1">
                    <template v-slot:[`item.action`]="props">
                        <!-- <v-icon v-if="!props.item.error" small class="mr-2" @click="doAction('edit', props)">
                            mode
                        </v-icon> -->
                        <!-- <v-icon v-if="props.item.status != 'E'" small class="mr-2" @click="doAction('play', props,$event)">
                            play_arrow
                        </v-icon> -->
                        <v-icon v-if="experimentMode==true" small class="mr-2" @click="doAction('check', props,$event)">
                            remove_red_eye
                        </v-icon>
                        <v-tooltip v-if="props.item.status == 'E'" top>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon v-bind="attrs" v-on="on"  small class="mr-2" @click="doAction('export', props,$event)">
                                    download
                                </v-icon>
                            </template>
                            <span>Download</span>
                        </v-tooltip>                        

                        <v-tooltip v-if="props.item.status == 'E' && props.item.prop.parent == undefined" top>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="doAction('add', props,$event)">
                                    content_copy 
                                </v-icon>
                            </template>
                            <span>Make a copy</span>
                        </v-tooltip>

                        <v-tooltip v-model="props.item.tooltip.replay" v-if="props.item.status == 'E' && props.item.prop.parent == undefined" top>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon v-bind="attrs" v-on="on"  class="mr-2" @click="doAction('replay', props,$event)">
                                    playlist_play
                                </v-icon>
                                <!-- {{ props.item.tooltip.replay }} -->
                            </template>
                            <span>Process more csv</span>
                        </v-tooltip>     
                        <v-tooltip top>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon v-bind="attrs" v-on="on"  small class="mr-2" @click="doAction('delete', props,$event)">
                                    delete
                                </v-icon>
                            </template>
                            <span>Remove</span>
                        </v-tooltip>                                           
                    </template>                    
                     <template v-slot:[`item.data`]="props">
                        <div class="truncate200">{{ props.item.data}}</div>
                    </template>     
                    <template v-slot:[`item.url`]="props">
                        <div class="truncate200">{{ props.item.url}}</div>
                    </template>                              
                    <template v-slot:[`item.result`]="props">
                        <div class="truncate200">{{ props.item.result}}</div>
                    </template>           
                    <template v-slot:[`item.startTime`]="props">
                        <div>{{ formatDate(props.item.startTime)}}</div>
                    </template>                   
                    <template v-slot:[`item.lastUpdate`]="props">
                        <div>{{ fromNow(props.item.lastUpdate)}}</div>
                    </template>                   

                    <template v-slot:[`item.status`]="props">
                        <template v-if="isNotFinish(props.item)">
                        <v-progress-circular :width=1.5 :size=14 indeterminate color="rgba(0, 0, 0, 0.54)"></v-progress-circular>
                        <!-- <v-icon  small class="mr-2">
                            rotate_right 
                        </v-icon> -->
                        <!-- <span v-if="props.item.prop.qrcodeTotal">{{statusProgress(props.item.prop)}}%  {{Math.max(props.item.prop.qrcodeIndex,props.item.prop._qrcodeIndex)-1}}/{{props.item.prop.qrcodeTotal-1}}</span> -->
                        </template>
                        <v-icon v-else small class="mr-2">
                            done  
                        </v-icon>

                        <!-- <div class="truncate200">{{ props.item.result}}</div> -->
                    </template>  
                    <template v-slot:[`item.selected`]="props">
                        <v-simple-checkbox off-icon="check_box_outline_blank" on-icon="check_box" @click="doAction('select', props,$event)"
                            v-model="props.item.selected" 
                            disabled
                        ></v-simple-checkbox>
                    </template>     
                    <template v-slot:[`item.name`]="props">
                        <span v-if="props.item.children.length > 0" >
                            <v-icon v-if="props.item.expand == false" class="mr-2" @click="doAction('expand', props,$event)">keyboard_arrow_right</v-icon> 
                            <v-icon v-else class="mr-2" @click="doAction('expand', props,$event)">keyboard_arrow_down</v-icon> 
                            {{ props.item.name }}</span>
                        <span v-else>
                            <v-icon v-if="props.item.prop.parent" small class="pl-2 mr-2">subdirectory_arrow_right</v-icon> 
                            <v-icon v-else style="opacity: 0;" class="mr-2">keyboard_arrow_right</v-icon>
                            {{ props.item.name }}</span>
                    </template>                                           
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <div class="pl-7">
                                <!-- <Label>Id {{item.Id}}</Label><br> -->
                                <Label>Total time {{Math.round((toSecond(item.lastUpdate) - toSecond(item.startTime))/1000)}} s</Label><br>
                                <template v-if="item.cookResult.error">
                                    <span class="red--text">{{item.cookResult.error}}</span>
                                </template>
                                <template v-else>
                                    <Label>Download Files:</Label>
                                    <span class="px-5"><a :href=item.cookResult.csv>Data File</a></span>
                                    <span class="px-5" v-for="(f, index) in item.cookResult.qrcode" :key=f><a :href=f>QRCode-{{index+1}}</a></span>
                                </template>
                            </div> 
                        </td>
                    </template>
            </v-data-table>
        </v-col>
    </v-row>
    <KCCSVProcessorDialog ref="csvProcessorDialog" v-model="showCSVProcessor" :isStage=isStage :taskID=currentID :replayMode=showCSVProcessorReplayMode :experimentMode=experimentMode  :csvUrl=csvUrl :readOnlyConfigItems=configItems :readOnlyConfigName=configName :readOnlyMode=showCSVProcessorReadonlyMode :previewData=configPreviewData @submit="submitEvent"></KCCSVProcessorDialog>
</v-container>
</template>

<style>
.kc-csv-processor-view div.truncate {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.kc-csv-processor-view div.truncate200 {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.kc-csv-processor-view .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: -1px 0px 0px -5px rgb(50 50 50 / 75%), inset 0px -4px 8px -5px rgb(50 50 50 / 75%);
}

.swal2-modal .swal2-styled.swal2-confirm:focus {
	-webkit-box-shadow: 0 0 0 3px rgb(211 113 49 / 50%);
	box-shadow: 0 0 0 3px rgb(211 113 49 / 50%);
}

.swal2-modal.swal2-popup {
    font-size: 0.9rem !important;
    font-family: Roboto, sans-serif !important;
}

th.text-start[aria-label="Name"] span {
    padding-left:36px;
}

</style>

<script>
import axios from "axios";
import utils from '../services/KCUtils.js'
import csvUtils from "../services/KCCSVUtils.js";
import KCCSVProcessorDialog from '../components/KCCSVProcessorDialog.vue'
//import KCSearchableSelect from '../components/KCSearchableSelect.vue'

import moment from 'moment'
//import Vue from 'vue';

//console.log("HomePage", process.env.VUE_APP_NOT_SECRET_CODE);
var myTimeout = null;
export default {
    
    name: "Kwang-CSVProcessorView",
    components: {
        KCCSVProcessorDialog,
        //KCSearchableSelect
    },
    props: {
        /*debug: {
            default: true,
        },*/
        serverMode: {
            default:  utils.getServerMode("mindfireOne"),
        },
        refreshIntervalSec: {
            default: 20,
        },
        experimentMode: {
            default: false,
        },
    },
    data() {

        var now = Date.now();
        var config = utils.getServerConfig(this.serverMode);
        //console.log(config);
        var endPoint =  config.endPoints.mindfireOne + "/api/csvprocessor";
        var davinciUrl = this.$store.getters.deployment.dvURL;
        var isStage = false;
        if(davinciUrl.indexOf("davincistage")>=0){
            isStage = true;
        }
        return {
            isStage: isStage,
            stageDomain: [],
            menu: false,
            endPoint: endPoint,
            config:config,
            showCSVProcessor:false,
            showCSVProcessorReplayMode: false,
            showCSVProcessorReadonlyMode:false,
            height: 400,
            expanded: [],
            singleExpand: true,
            window: {
                width: 0,
                height: 0
            },
            statusItems:[],
            statusHeader: [
                // { text: "Id", value: "Id", sortable: false},
                // { text: "", value: "selected", sortable: false,width: '30px'},
                { text: "Name", value: "name", sortable: false},
                { text: "Owner", value: "user", sortable: false},
                { text: "Source", value: "cookSource", sortable: false},
                // { text: "Config Data", value: "data", sortable: false},
                { text: "StartTime", value: "startTime", sortable: false},
                { text: "LastUpdate", value: "lastUpdate", sortable: false},
                { text: "Status", value: "status", sortable: false},
                // { text: "progress", value: "progress", sortable: false},
                // { text: "result", value: "result", sortable: false},
                { text: "Action", value: "action", sortable: false},
                // { text: '', value: 'data-table-expand'},
            ],
            //sortBy : "startTime",
            sortBy : null,
            sortDesc: true,
            csvUrl: undefined,
            configItems: [],
            configName: "",
            configPreviewData:null,
            currentID: null,
            // tooltip:{
            //     replay: false,
            // }
        }
    },
    computed: {
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
    },
    methods: {
        clearToopTips(){
            this.statusItems.forEach((item)=>{
                Object.keys(item.tooltip).forEach((index)=>{
                    item.tooltip[index] = false;
                });
            })
        },
        isNotFinish(item){
            if(item.status != "E") return true;
            if(item.children!=null){
                for(let x in item.children){
                    if(item.children[x].status != "E") return true;
                }
            }
            return false;
        },
        searchableSelect(item){
            console.log(item);
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.height = Math.max(400, (this.window.height - 100) / 2);
        },
        async loadTaskStatus(checkExpand = false){
            console.log("loadTaskStatus",checkExpand)
            let expands = [];
            if(checkExpand){
                for(let x=0;x<this.statusItems.length;x++){
                    if(this.statusItems[x].expand) expands.push(this.statusItems[x].Id);
                }
            }
            console.log("loadTaskStatus","expands",expands);
            var accountID = this.account; //this.$store.getters.user.account;
            var items = await this.getTaskStatus(accountID);
            var hasWaitingTask = false;
            // parse json Field
            for(let x =0;x<items.length;x++){
                var item = items[x];
                console.log(item);
                item.cookResult = JSON.parse(item.result);
                item.prop = JSON.parse(item.prop);
                item.user = item.prop.user;
                // create hide source 
                item.cookSource = this.cookSourceUrl(item.prop.source);
                if(item.status!="E"){
                    hasWaitingTask = true;
                }
                item.startTimeLocal = moment(item.startTime).format('lll')
                item.selected = false;
                item.children = [];
                item.expand = false;
                item.tooltip = {
                    replay: false,
                    copy: false,
                    download: false,
                    remove: false,
                };
                // if("parent" in item.prop){
                //     childItems.push(item);
                // }else{
                //     parentItems.push(item);
                // }
            }
            items.sort((a,b)=>b.startTime.localeCompare(a.startTime));
            //console.log(this.stageDomain);
            items = items.filter((x)=>{
                //console.log(isStage,(this.stageDomain.indexOf(x.prop.domain) >= 0),x.prop.domain);
                return this.isStage == (this.stageDomain.indexOf(x.prop.domain) >= 0)
            });
            var parentItems = items.filter((x)=> {return "parent" in x.prop == false} )
            var childItems = items.filter((x)=> {return  "parent" in x.prop == true} )
            //console.log("childItems",childItems);
            for(let x=0;x<childItems.length;x++){
                for(let y=0;y<parentItems.length;y++){
                    if(childItems[x].prop.parent == parentItems[y].Id){
                        parentItems[y].children.push(childItems[x]);
                    }
                }
            }
            console.log("loadTaskStatus",parentItems);
            // expand if necessary
            for(let x=0;x<parentItems.length;x++){
                if(expands.indexOf(parentItems[x].Id)>=0){
                    parentItems[x].expand = true;
                    parentItems.splice(x+1, 0, ... parentItems[x].children);
                }
            }
            this.statusItems = parentItems;
            //this.expanded = [];
            if(hasWaitingTask == true){
                if(myTimeout == null){
                    console.log("setInterval")
                    myTimeout = setInterval(()=>{this.loadTaskStatus(true)}, this.refreshIntervalSec*1000);
                }
            }else{
                if(myTimeout != null){
                    console.log("clearInterval")
                    clearInterval(myTimeout);
                    myTimeout = null;
                }
            }
        },
        // hide full path from user 
        cookSourceUrl(url){
            var filename = url.substring(url.lastIndexOf('/')+1);
            return filename;
        },
        formatDate(date){
            return moment(date).format('lll');
        },
        fromNow(date){
            return moment(date).fromNow();
        },
        toSecond(date){
            return moment(date).valueOf();
        },
        statusProgress(prop){
            var current = Math.max(prop.qrcodeIndex,prop._qrcodeIndex);
            return ((current-1)*100.00/(prop.qrcodeTotal-1)).toFixed(2);
        },
        itemRowBackground: function (item) {
            return item.cookResult.error? 'red lighten-3' : '';
        },
        async getTaskStatus(accountID){
            try{  
                var resp = await axios.post(this.endPoint,{
                    cmd:"listStatus",
                    acctID: accountID,
                });
                //console.log("getTaskStatus",resp.data);
                return resp.data.result;
            }catch(ex){
                console.log(ex);
            }   
        },
        async deleteTaskStatus(id){
            var accountID = this.account; //this.$store.getters.user.account;
            try{  
                var resp = await axios.post(this.endPoint,{
                    cmd:"deleteStatus",
                    acctID: accountID,
                    id:id,
                });
                //console.log("deleteTaskStatus",resp.data);
                return resp.data.result;
            }catch(ex){
                console.log(ex);
            }             
        },
        async playTask(id){
            try{  
                var resp = await axios.get(`${this.endPoint}?cmd=process&id=${id}`);
                return resp.data.result;
            }catch(ex){
                console.log(ex);
            }     
        },
        async addNewTaskClick(byUrl){
            this.configItems = [];
            if(byUrl == true){
                this.csvUrl = 
                //"https://mfdavinci.s3.us-west-1.amazonaws.com/file/qrcode/30630-219.zip";
                "https://mfdavinci.s3.us-west-1.amazonaws.com/file/qrcode/30630-220.zip";
                //"https://mfdavinci.s3.us-west-1.amazonaws.com/file/qrcode/34283-237.zip";
            }else{
                this.csvUrl = undefined;
            }
            this.showCSVProcessorDialog();            
        },
        async refreshTaskClick(){
            await this.loadTaskStatus(true);
        },
        async rowClick(param){
            console.log(param);
            // var Id = param.Id;
            this.csvUrl = param.url,
            this.configItems = JSON.parse(param.data);
            this.configName = param.name;
            this.configPreviewData = param.prop.previewData;
            this.showCSVProcessorDialog(true);
        },
        async doAction(action,param,event){
            event.preventDefault();
            event.stopPropagation();
            console.log("doAction",action,param);
            var Id = param.item.Id;    
            if(action == "check"){
                //console.log(param.item.result);
                var url;
                var index;
                try{                                    
                    url = param.item.cookResult.csv;
                    await this.checkZipFile(url);
                }catch(ex){
                    alert("ERROR at CSV " + url);
                    return;
                }                    
                try{                                    
                    for(let x in param.item.cookResult.qrcode){
                            url = param.item.cookResult.qrcode[x];
                            index = parseInt(x)+1;
                            console.log(x,url);
                            await this.checkZipFile(url);
                            console.log("done",index,"/",param.item.cookResult.qrcode.length);
                    }
                }catch(ex){
                    alert("ERROR at QRCOde-" + index + " " + url);
                    return;
                }

                alert("success no error");
            }
            if(action == "edit"){
                this.csvUrl = param.item.url,
                this.configItems = JSON.parse(param.item.data);
                this.configPreviewData = param.item.prop.previewData;
                this.showCSVProcessorDialog(true);
            }
            if(action == "delete"){
                var result = await this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    ... csvUtils.getSwalYesNoOption()
                    // confirmButtonColor: '#3085d6',
                    // cancelButtonColor: '#d33',
                    // confirmButtonText: 'Yes, delete it!'
                });
                console.log(result);
                if (result.isConfirmed) {
                    await this.deleteTaskStatus(Id);
                    await this.loadTaskStatus(true);
                }
            }
            if(action == "expand"){
                param.item.expand = !param.item.expand;
                for(let x=0;x < this.statusItems.length;x++){
                    if(this.statusItems[x] == param.item){
                        if(param.item.expand == true){
                            //for(let i in param.item.children){
                                this.statusItems.splice(x+1, 0, ... param.item.children);
                            //}
                        }else{
                            let count = 0;
                            for(let i = x+1;i<this.statusItems.length;i++){
                                if(this.statusItems[i].prop.parent != undefined){
                                    count++;
                                }else{
                                    break;
                                }
                            }
                            if(count>0){
                                this.statusItems.splice(x+1,count);
                            }
                        }
                        break;
                    }
                }
                
            }
            if(action == "export"){
                //console.log(param.item);
                for(var x in this.expanded){
                    if(this.expanded[x].Id == param.item.Id){
                        this.expanded.splice(x,1);
                        return;
                    }
                }
                this.expanded.push(param.item);
                // var index = this.expanded.indexOf(param.item);
                // if(index>=0){
                //     this.expanded.splice(index,1);
                // }else{
                //     this.expanded.push(param.item);
                // }
            }
            if(action == "play"){
                await this.playTask(Id);
                await this.loadTaskStatus(true);
            }
            if(action == "replay"){
                console.log(param);
                this.csvUrl = undefined,
                this.configItems = JSON.parse(param.item.data);
                let config = this.hasWalletPass();
                if(config){
                    let detail = JSON.parse(config.detail);
                    detail.copy = false;
                    config.detail = JSON.stringify(detail);
                }
                //this.configName = param.item.name + "Copy";
                this.configPreviewData = param.item.prop.previewData;
                this.showCSVProcessorDialog(false,true,Id);
            }
            if(action == "add"){
                console.log(param);
                this.csvUrl = undefined,
                this.configItems = JSON.parse(param.item.data);
                let config = this.hasWalletPass();
                if(config){
                    let detail = JSON.parse(config.detail);
                    detail.copy = true;
                    config.detail = JSON.stringify(detail);
                }                
                this.configPreviewData = param.item.prop.previewData;
                this.showCSVProcessorDialog();
            }
            if(action == "select"){
                console.log(param);
                param.item.selected = !param.item.selected;
            }
        },
        hasWalletPass(){
            for(let x in this.configItems){
                var config = this.configItems[x];
                if(config.process == "WalletPass"){
                    // let detail = JSON.parse(config.detail);
                    // detail.copy = true;
                    // config.detail = JSON.stringify(detail);
                    return config;
                }
            }
            return false;
        },
        async submitEvent(value){
            console.log("submitEvent",value);
            await this.loadTaskStatus(true);            
            if(value.parent != null){
                for(let x=0;x< this.statusItems.length;x++){
                    if(this.statusItems[x].Id == value.parent){
                        this.statusItems[x].expand = true;
                    }
                }
            }
            // await this.playTask(value.id);
            // await this.loadTaskStatus();            
        },
        showCSVProcessorDialog(readOnlymode=false,replayMode=false,taskID=null){
            this.showCSVProcessorReadonlyMode = readOnlymode;
            this.showCSVProcessorReplayMode = replayMode;
            this.currentID = taskID;
            this.showCSVProcessor = true;            
        },
        async checkZipFile(url){
            console.log(this.$refs.csvProcessorDialog);
            console.log("checkZipFile",url);
            await this.$refs.csvProcessorDialog.checkZipFromUrl(url);
        },
        async getStageDomain(){
            var ret = await axios.get(`${this.endPoint}?cmd=getStageDomain`);
            console.log("getStageDomain", ret.data);
            return ret.data.result;
        },

    },
    created() {
        console.log("mounted", this.$store);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        //await this.setThePortalSettings();
    },
    
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
        if(myTimeout!=null){
            clearInterval(myTimeout);
        }
    },
    async mounted() {
        // load current status data
        if (process.env.VUE_APP_JWT == "mockup") {
            //console.log("CSVProcessorView","mount","debug mode");
            axios.defaults.headers.common['Authorization'] = "bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcCI6IjQ5LjIyOC4xMDIuMTE4IiwiZW1haWwiOiJrZHV0dGFAbWluZGZpcmVpbmMuY29tIiwicHUiOnRydWUsImF1Ijp0cnVlLCJwYSI6ZmFsc2UsImFpZCI6MzQzMDcsInBhaWQiOjE3NzIzLCJpc3MiOiJpZGVudGl0eS5tZGwuaW8iLCJpYXQiOjE2NDk2NjYxMjMsImV4cCI6MTY0OTY5NDkyM30.Z8bZvKeSg1I8L31F1LQY9AeEZu8gG3jYkzLAuOUQnn5lWjJmtb8Vqm3dPRbzhQKDYxqDyduCGoJUZ1AKkPH99K9qEZjFJdLzI_AoK6SHVjaBxC8PmKsm360-mNmsffbFCMZx7DVYwAuljmjNT-hKT3g-1mORLuSajgkvxz2M8KuGoZFyFvxRVKroQLBnVNE_YBJ64TZFZaBB0gDK9bVr-4pXz_wML2rxUbj9dL7L9ZOT1ZzbySpaS8zl7zx27PGdtWJQtNMTH5DGaH5Gx0gGRQOdJBQ9Oarb7OIRqnrD-rPr00Xl6rgght5l8Bedlz8ai2Vvmly6d-CJ0iifk9xHAQ";
        }
        this.stageDomain = await this.getStageDomain();
        //this.isStage = this.stageDomain.indexOf(window.location.origin) >= 0;
        await this.loadTaskStatus();
        
    },
    beforeUnmount() {},
    watch: {
        account: {
            async handler(val) {
                //this.getAllData();
                //this.getHomePageData();
                await this.loadTaskStatus();
            },
            deep: true
        },         
        showCSVProcessor: {
            async handler(val) {
                if(val == false){
                    setTimeout(()=>{
                        this.clearToopTips();
                    },500);
                    
                }
            },
        }
        // account: {
        //     async handler(val) {
        //         //this.getAllData();
        //         //this.getHomePageData();
        //     },
        //     deep: true
        // },
    },
}
</script>
